import Vue from "vue";

export default Vue.extend({
    methods: {
        highlight(value: number, indicator: number) {
            if (Math.abs(Number(value)) >= indicator) {
                return true
            } else {
                return false
            }
        }
    }
})
;
