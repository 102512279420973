
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import InformationDDC from "./InformationDDC.vue";
import { BleedingDisorder } from "../../models/reports/HemophiliavA&BSeverity/bleedingdisorder";
import {
  genderTypeResult,
  GenderType,
} from "../../models/reports/HemophiliavA&BSeverity/hemophiliavA&BSeverityData";
import roundValues from "../../mixins/roundValues";
import highlightReports from "../../mixins/highlightReports";
import { formatFinancialAmounts } from "../../utils/format";
export default Vue.extend({
  name: "HemophiliaA-and-B-Severity",
  mixins: [roundValues, highlightReports],
  components: { InformationDDC },
  data: () => ({
    data: [],
    formatFinancialAmounts,
    isLoading: false,
    genders: [],
    genderSelected: [],
    severities: [],
    severitiesSelected: [],
    diseaseTypes: [],
    diseaseTypesSelected: [],
    payload: {
      year: null,
      hemophiliaType: [] as number[],
      genderType: [] as number[],
      severityType: [] as number[],
    },
  }),
  computed: {
    title() {
      return i18n.t("reports.ddcreports.listReports.hemophiliaA&BSeverity");
    },
    yearSelected() {
      return this.$store.getters["ddcreport/getYearSelected"].year;
    },
  },
  methods: {
    getFieldBySeverity(severity: string, gender: genderTypeResult) {
      switch (severity) {
        case "Carrier": {
          return [
            gender.current_Carrier,
            gender.previous_Carrier,
            gender.carrier_Perc_Diff,
          ];
        }
        case "Mild": {
          return [
            gender.current_Mild,
            gender.previous_Mild,
            gender.mild_Perc_Diff,
          ];
        }
        case "Moderate": {
          return [
            gender.current_Moderate,
            gender.previous_Moderate,
            gender.moderate_Perc_Diff,
          ];
        }
        case "Severe": {
          return [
            gender.current_Severe,
            gender.previous_Severe,
            gender.severe_Perc_Diff,
          ];
        }
        case "Unknown": {
          return [
            gender.current_SeverityUnknown,
            gender.previous_SeverityUnknown,
            gender.severityUnknown_Perc_Diff,
          ];
        }
      }
    },
    exportData() {
      const arrayIDGenders: number[] = this.genderSelected.map(function (
        item: BleedingDisorder
      ) {
        return item.id;
      });
      const arrayIDDiseaseType: number[] = this.diseaseTypesSelected.map(
        function (item: BleedingDisorder) {
          return item.id;
        }
      );
      const arrayIDSeverities: number[] = this.severitiesSelected.map(function (
        item: BleedingDisorder
      ) {
        return item.id;
      });
      this.payload.genderType = arrayIDGenders;
      this.payload.hemophiliaType = arrayIDDiseaseType;
      this.payload.severityType = arrayIDSeverities;
      this.payload.year = this.yearSelected;
      axios
        .post(`report/ddc/HemophiliaSeverity/export`, this.payload, {
          responseType: "blob",
        })
        .then((res) => {
          const fileName =
            `${i18n.t("reports.ddcreports.pagetitle")} -` + this.title;
          this.downloadExcel(fileName, res.data);
        })
        .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], { type: "application/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
    loadGender() {
      axios
        .get(`Report/DDC/HemophiliaSeverity/genderType`)
        .then((res) => {
          this.genders = res.data;
        })
        .catch((err) => console.log(err));
    },
    loadSeverities() {
      axios
        .get(`Report/DDC/HemophiliaSeverity/severity`)
        .then((res) => {
          this.severities = res.data;
        })
        .catch((err) => console.log(err));
    },
    loadDiseaseType() {
      axios
        .get(`Report/DDC/HemophiliaSeverity/hemophiliaType`)
        .then((res) => {
          this.diseaseTypes = res.data;
        })
        .catch((err) => console.log(err));
    },
    loadData() {
      this.isLoading = true;
      const arrayIDGenders: number[] = this.genderSelected.map(function (
        item: BleedingDisorder
      ) {
        return item.id;
      });
      const arrayIDDiseaseType: number[] = this.diseaseTypesSelected.map(
        function (item: BleedingDisorder) {
          return item.id;
        }
      );
      const arrayIDSeverities: number[] = this.severitiesSelected.map(function (
        item: BleedingDisorder
      ) {
        return item.id;
      });
      this.payload.genderType = arrayIDGenders;
      this.payload.hemophiliaType = arrayIDDiseaseType;
      this.payload.severityType = arrayIDSeverities;
      this.payload.year = this.yearSelected;
      axios
        .post("report/DDC/HemophiliaSeverity", this.payload)
        .then((res: any) => {
          this.data = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
    getGenderNameByID(id: number) {
      const element: GenderType = this.genders.filter(
        (genderType: GenderType) => genderType.id === id
      )[0];
      return element.name;
    },
    getSeverityNameByID(id: number) {
      const element: GenderType = this.severities.filter(
        (genderType: GenderType) => genderType.id === id
      )[0];
      return element.name;
    },
  },
  created() {
    this.loadGender();
    this.loadSeverities();
    this.loadDiseaseType();
  },
});
